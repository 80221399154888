import {
  mediaQueries,
  size,
  H3,
  H4,
  typography,
  Button as ButtonRaw,
} from '@everlywell/leaves';
import styled from 'styled-components';

import SectionTitle from '../../Enterprise/SectionTitle';

export const Title = styled(SectionTitle)`
  text-align: center;
  padding-top: ${size.xl1}px;
  ${mediaQueries.forTabletHorizontalUp} {
    padding-top: 0;
    padding-bottom: 0;
  }
`;

export const Headline = styled(H3)`
  margin-bottom: ${size.md}px;
  font-weight: normal;
  ${mediaQueries.forTabletHorizontalUp} {
    margin-bottom: ${size.xs1}px;
  }
`;

export const SubHeadline = styled(H4)`
  ${typography.subtitle}
  margin-bottom: ${size.md}px;

  ${mediaQueries.forTabletHorizontalUp} {
    margin-bottom: ${size.xs1}px;
  }
`;

export const PostScript = styled.p`
  ${typography.captionText}
`;

export const Button = styled(ButtonRaw)`
  margin: ${size.lg}px auto;
`;

export const VideoContainer = styled.div`
  display: none;
  overflow: hidden;
  padding-top: 56.25%; /* 16:9 aspect ratio */
  margin-top: -${size.xl1}px; /* video sits flush with top of section */
  position: relative;

  ${mediaQueries.forTabletVerticalUp} {
    display: block;
    margin-top: 0;
  }
`;

export const MobileVideoContainer = styled(VideoContainer)`
  display: block;

  ${mediaQueries.forTabletVerticalUp} {
    display: none;
  }
`;

export const Video = styled.iframe`
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;
