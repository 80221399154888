import React from 'react';
import { DefaultContentSection } from '../../../components/PageSections/DefaultContentSection';
import { CTA } from '../../../utils/types';

type Markdown = {
  childMarkdownRemark: {
    html: string;
  };
};

export interface DefaultProps {
  content: {
    slug: string;
    title: string;
    headline: string;
    subHeadline: string;
    subHeadlineDescription?: { subHeadlineDescription: string };
    description: Markdown;
    mainCta?: CTA;
    className: string;
    backgroundColor?: 'Primary' | 'Secondary';
    embeddedVideoLink?: string;
  };
}

export const DefaultSection: React.FC<DefaultProps> = ({ content }) => {
  const {
    slug,
    title,
    headline,
    subHeadline,
    subHeadlineDescription,
    description,
    mainCta,
    className,
    backgroundColor,
    embeddedVideoLink,
  } = content;
  return (
    <DefaultContentSection
      uid={slug}
      title={title}
      headline={headline}
      subHeadline={subHeadline}
      subHeadlineDescription={
        subHeadlineDescription?.subHeadlineDescription || ''
      }
      body={description?.childMarkdownRemark.html || ''}
      cta={mainCta}
      className={className}
      backgroundColor={backgroundColor}
      videoLink={embeddedVideoLink}
    />
  );
};

export default DefaultSection;
