import { Row, Col, Container } from '@everlywell/leaves';
import React from 'react';
import ewAnalytics from 'utils/analytics';
import { ANALYTICS } from 'utils/constants/analytics';
import { generateKey } from 'utils/helpers';

import { MarkdownContent } from '../../../components/Contentful/MarkdownContent';
import { CTA } from '../../../utils/types';
import SectionWrapper from './../../SectionWrapper';
import * as S from './styles';

export interface DefaultSectionProps {
  uid?: string;
  title?: string;
  headline?: string;
  subHeadline?: string;
  subHeadlineDescription?: string;
  body?: string;
  cta?: CTA;
  className?: string;
  backgroundColor?: 'Primary' | 'Secondary';
  videoLink?: string;
  onCtaClick?: () => any;
  ctaDisabled?: boolean;
}

export const DefaultContentSection: React.FC<DefaultSectionProps> = ({
  uid = generateKey(),
  title,
  headline,
  subHeadline,
  subHeadlineDescription,
  body,
  cta,
  className,
  backgroundColor,
  videoLink,
  onCtaClick,
  ctaDisabled,
}) => {
  const handleClick = (e: Event): void => {
    ewAnalytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        label: `${uid}-cta`,
      },
    });

    if (onCtaClick) {
      e.preventDefault();
      onCtaClick();
    }
  };

  return (
    <SectionWrapper
      id={uid}
      data-testid={`${uid}-section`}
      className={className}
      backgroundColor={backgroundColor}
    >
      <Container>
        <Row center="xs">
          <Col>
            {title ? <S.Title text={title} /> : null}
            {headline ? <S.Headline>{headline}</S.Headline> : null}
            {subHeadline ? <S.SubHeadline>{subHeadline}</S.SubHeadline> : null}
            {body ? <MarkdownContent body={body} /> : null}
            {cta ? (
              <S.Button
                appearance={cta.appearance ? cta.appearance : 'primary'}
                href={cta.url}
                data-testid={`${uid}-cta`}
                // @ts-ignore TODO: Temporary ts-ignore to not block a deploy.
                onClick={handleClick}
                isDisabled={ctaDisabled}
              >
                {cta.text}
              </S.Button>
            ) : null}
            {subHeadlineDescription ? (
              <S.PostScript>{subHeadlineDescription}</S.PostScript>
            ) : null}
          </Col>
        </Row>
      </Container>
      <>
        {videoLink ? (
          <>
            <Container>
              <Row center="xs">
                <Col xs={12} md={7}>
                  <S.VideoContainer>
                    <S.Video
                      data-testid={`${uid}-video`}
                      aria-hidden="true"
                      src={videoLink}
                      width={560}
                      height={315}
                      allow="autoplay; fullscreen"
                    />
                  </S.VideoContainer>
                </Col>
              </Row>
            </Container>
            <S.MobileVideoContainer>
              <S.Video
                data-testid={`${uid}-video`}
                aria-hidden="true"
                src={videoLink}
                width={560}
                height={315}
                allow="autoplay; fullscreen"
              />
            </S.MobileVideoContainer>
          </>
        ) : null}
      </>
    </SectionWrapper>
  );
};

export default DefaultContentSection;
